import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import App from './components/App/App';
import { levi9TenantId, msalConfig } from './config/authConfig';
import './index.module.scss';

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const authProvider = new PublicClientApplication(msalConfig);

authProvider.initialize().then(() => {
  if (!authProvider.getActiveAccount()) {
    const account = authProvider
      .getAllAccounts()
      .find((account) => account.tenantId === levi9TenantId);
    if (account) {
      authProvider.setActiveAccount(account);
    }
  }

  // Optional - This will update account state if a user signs in from another tab or window
  authProvider.enableAccountStorageEvents();

  authProvider.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const account = event.payload as AccountInfo;
      if (account.tenantId === levi9TenantId) {
        authProvider.setActiveAccount(account);
      }
    }
  });
});

root.render(
  <React.StrictMode>
    <MsalProvider instance={authProvider}>
      <ToastContainer />
      <App />
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
