import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

export const levi9TenantId = '40758481-7365-442c-ae94-563ed1606218';

export const msalConfig: Configuration = {
  auth: {
    clientId: '32de46bf-f9bd-40a0-bc34-702a46ee9c7b',
    authority: `https://login.microsoftonline.com/${levi9TenantId}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
};
