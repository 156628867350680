import React from 'react';
import { useRoutes } from 'react-router-dom';
import {
  ACTIVITIES_OVERVIEW,
  CREATE_ACTIVITY_PAGE,
  LOGIN_PAGE,
  TASKS_OVERVIEW,
} from '../../types/constants/routes';

const LoginPage = React.lazy(() => import('../pages/Login/LoginPage'));
const AuthLayout = React.lazy(() => import('../pages/Layout/AuthLayout'));
const HomePage = React.lazy(() => import('../pages/Home/HomePage'));
const CreateActivityPage = React.lazy(() => import('../pages/CreateActivity/CreateActivityPage'));
const ActivityOverviewPage = React.lazy(
  () => import('../pages/ActivityOverview/ActivityOverviewPage'),
);
const TaskOverviewPage = React.lazy(() => import('../pages/TaskOverview/TaskOverviewPage'));
const PageNotFound = React.lazy(() => import('../pages/PageNotFound/PageNotFound'));

export default function Router() {
  let element = useRoutes([
    { path: LOGIN_PAGE, element: <LoginPage />, index: true },
    {
      element: <AuthLayout />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: CREATE_ACTIVITY_PAGE, element: <CreateActivityPage /> },
        { path: ACTIVITIES_OVERVIEW, element: <ActivityOverviewPage /> },
        { path: TASKS_OVERVIEW, element: <TaskOverviewPage /> },
        { path: '*', element: <PageNotFound /> },
      ],
    },
  ]);

  return element;
}
