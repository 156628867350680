import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import Router from '../Router/router';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Suspense fallback={<></>}>
          <Router />
        </Suspense>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
